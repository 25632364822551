export const LOGIN_MIN_LENGTH = 3;

export const LOGIN_MAX_LENGTH = 150;
export const LOGIN_REGEX = /^[a-z0-9-_]{3,150}$/i;

export const PASSWORD_MIN_LENGTH = 4;

export const PASSWORD_MAX_LENGTH = 150;

export const EMAIL_REGEX = /^[a-z0-9-_.]+@[a-z0-9-_.]+\.[a-z]{2,}$/i;

export const AGE_MIN = 1900;

export const AGE_MAX = new Date().getFullYear();

export const GENDER_ARRAY = [
  { value: 0, label: 'Mężczyzna' },
  { value: 1, label: 'Kobieta' },
  { value: 2, label: 'Nie podano' },
  { value: 3, label: 'Inna' },
];

export const EVENTS_CATEGORIES = [
  { value: 0, label: 'obt' },
  { value: 1, label: 'cbt' },
  { value: 2, label: 'close' },
  { value: 3, label: 'premier' },
  { value: 4, label: 'new_server' },
  { value: 5, label: 'new_class' },
  { value: 6, label: 'alpha_test' },
  { value: 7, label: 'new_extension' },
  { value: 8, label: 'new_update' },
  { value: 9, label: 'new_payment' },
  { value: 10, label: 'others' },
  { value: 11, label: 'early_access' },
];

export const localesName = 'inputs';

export const topicOptions = [
  { value: 0, label: 'Problemy techniczne / Technical issues' },
  { value: 1, label: 'Zapytanie o reklamę / Advertisment proposal' },
  { value: 2, label: 'Propozycja współpracy / Partnership proposal' },
  { value: 3, label: 'Inne' },
];
