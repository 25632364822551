import { MainPage } from 'components/MainPage';
import { Seo } from 'components/Seo';
import { dehydrate, QueryClient } from 'react-query';
import { typesCachedData } from 'utils/typesCachedData';
import { fetchPostsList } from 'db/models';
import { getEventsMonthData } from 'db/models/events';

const Component = () => (
  <>
    <Seo />
    <MainPage isSg />
  </>
);

export default Component;

export const getStaticProps = async () => {
  const queryClient = new QueryClient();
  const cached = Object.entries(typesCachedData);

  await Promise.all(cached.map(([type, func]) => queryClient.prefetchQuery(['cached', type], func)));

  await queryClient.prefetchQuery(['posts', '1', '', ''], () => fetchPostsList({ query: { page: 1 } }));
  await queryClient.prefetchQuery(['calendar', new Date().toISOString().slice(0, 7)], () =>
    getEventsMonthData(new Date())
  );

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 60,
  };
};
